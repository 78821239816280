import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "256",
      cy: "256",
      r: "256",
      style: {"fill":"#f0f0f0"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M496.077 345.043C506.368 317.31 512 287.314 512 256s-5.632-61.31-15.923-89.043H15.923C5.633 194.69 0 224.686 0 256s5.633 61.31 15.923 89.043L256 367.304z",
      style: {"fill":"#0052b4"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512",
      style: {"fill":"#d80027"}
    }, null, -1)
  ])))
}
export default { render: render }